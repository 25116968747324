<script
  setup
  lang="ts"
  generic="TSlide extends Record<string, unknown> | string"
>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Virtual, Keyboard, A11y, Pagination, Navigation } from 'swiper/modules'
import type { CSSProperties } from 'vue'
import type { SwiperOptions, Swiper as SwiperClass } from 'swiper/types'

export type VirtualBaseCarouselSwiperOptions = Omit<
  SwiperOptions,
  'url' | 'width' | 'height' | 'userAgent' | 'swipeHandler'
> & {
  url?: string
  width?: number
  height?: number
  userAgent?: string
  swipeHandler?: boolean
}

defineSlots<{
  'slider-content'(p: { slide: TSlide; slideIndex: number }): any
  navigation(p: { position: number }): any
}>()

const emits = defineEmits<{
  'click-on-image': []
  'slide-change-active-index': [number]
  'handle-click-container': [MouseEvent]
}>()

const props = withDefaults(
  defineProps<{
    baseImageStyleObject?: CSSProperties
    className?: string
    cover?: boolean
    imageAttr?: { alt: string }
    loop?: boolean
    navigation?: boolean
    pagination?: boolean
    slides?: TSlide[]
    swiperClass?: string
    swiperNavElClass?: string
    swiperSlideClass?: string
    swiperOptions?: VirtualBaseCarouselSwiperOptions
    virtual?: boolean
  }>(),
  {
    baseImageStyleObject: () => ({}),
    cover: true,
    className: '',
    imageAttr: () => ({
      alt: '',
    }),
    loop: false,
    navigation: false,
    pagination: false,
    slides: () => [],
    swiperClass: '',
    swiperNavElClass: 'swiper',
    swiperSlideClass: '',
    swiperOptions: () => ({}),
    virtual: true,
  },
)

const virtualCarousel = ref<SwiperClass>()
const swiperIndex = ref(0)

const onSwiper = (swiper: SwiperClass) => {
  virtualCarousel.value = swiper
}

const slideNext = () => {
  virtualCarousel.value?.slideNext()
}
const slidePrev = () => {
  virtualCarousel.value?.slidePrev()
}
const slideTo = (index: number, speed?: number) => {
  virtualCarousel.value?.slideTo(index, speed)
}
const onSlideChange = (swiper: SwiperClass) => {
  swiperIndex.value = props.swiperOptions.loop
    ? swiper.realIndex
    : swiper.activeIndex
  emits('slide-change-active-index', swiperIndex.value)
}
const clickOnImage = () => {
  emits('click-on-image')
}
const handleClickContainer = (e: MouseEvent) => {
  emits('handle-click-container', e)
}

defineExpose({
  slideIndex: swiperIndex,
  slideTo,
  slideNext,
  slidePrev,
})

// wait for slots to be mounted, avoids swiper initialization issues on non-existent elements (pagination/navigation)
const componentMounted = ref(false)
onMounted(() => {
  componentMounted.value = true
})
</script>

<template>
  <div
    v-if="componentMounted"
    :class="['swiper relative', className]"
    @click.self="handleClickContainer"
  >
    <NuxtErrorBoundary @error="() => {}">
      <Swiper
        cache
        :class="['h-full', swiperClass]"
        :loop="loop"
        :modules="[Virtual, A11y, Pagination, Keyboard, Navigation]"
        :navigation="{
          enabled: navigation,
          nextEl: `.${className} .${swiperNavElClass}--next`,
          prevEl: `.${className} .${swiperNavElClass}--prev`,
          disabledClass: 'swiper-button-disabled',
        }"
        :pagination="{
          el: '.swiper-pagination',
          enabled: pagination,
          clickable: true,
        }"
        :resize-observer="false"
        :virtual="virtual"
        v-bind="swiperOptions"
        @slideChange="onSlideChange"
        @swiper="onSwiper"
      >
        <SwiperSlide
          v-for="(slide, slideIndex) in slides"
          :key="slideIndex"
          :class="swiperSlideClass"
          :virtual-index="slideIndex"
        >
          <slot
            name="slider-content"
            :slide-index="slideIndex + 1"
            :slide="slide"
          >
            <div class="flex h-full justify-center">
              <BaseNuxtImg
                aspect-ratio="16/9"
                :alt="imageAttr.alt"
                :cover="cover"
                :src="typeof slide === 'string' ? slide : slide.src"
                :style-object="baseImageStyleObject"
                @click="clickOnImage"
              />
            </div>
          </slot>
        </SwiperSlide>

        <template #container-end>
          <slot name="navigation" :position="swiperIndex + 1">
            <BaseCarouselArrow v-if="navigation" direction="prev" />
            <VirtualBaseCarouselPaginationV1 v-if="pagination" />
            <BaseCarouselArrow v-if="navigation" direction="next" />
          </slot>
        </template>
      </Swiper>
    </NuxtErrorBoundary>
  </div>
</template>
